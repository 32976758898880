import React, { useContext } from "react";
import { DashboardFilterContext } from "../Dashboard/DashboardFilterContext";
import useDetailsKpiIndex from "./hooks/useDetailsKpiIndex";
import PageLayout from "../../PageLayout";
import { EButton, EPageTitle } from "../../libs/ui";
import MentoringCompliance from "./Pages/Mentoring/MentoringCompliance";
import { Select } from "@mantine/core";
import MonitoringCompliance from "./Pages/Monitering/MonitoringCompliance";
import { AppContext } from "../../AppContext";
import { ROLES } from "../../Routes/routes";
import SchoolLevelMentoring from "./Pages/SchoolLevelMentoring/SchoolLevelMentoring";
import moment from "moment";

export default function DetailsKpiIndex() {
  const { user, role } = useContext(AppContext);
  const {
    selectedMonth,
    setSelectedMonth,
    selectedDistrict,
    setSelectedDistrict,
    selectedBlock,
    setSelectedBlock,
    selectedCluster,
    setSelectedCluster,
    districts,
    blocks,
    clusterSchools,
    setIsLoadingSchoolVisit,
    isSearching,
    setIsSearching,
    isLoadingSchoolVisit,
    handleSearch,
    selectedSession,
    setSelectedSession,
  } = useDetailsKpiIndex();
  return (
    <DashboardFilterContext.Provider
      value={{
        selectedMonth,
        selectedDistrict,
        selectedBlock,
        selectedCluster,
        selectedSession,
        districts,
        blocks,
        setIsLoadingSchoolVisit,
        isSearching,
        setIsSearching,
      }}
    >
      <PageLayout>
        <EPageTitle title="Academic Mentoring" />
        {/* FILTERS */}
        <div className="filter-container">
          {/* <h4>Filters</h4> */}
          <div className="flex justify-between gap-3">
            <div className="flex gap-5">
              <Select
                label="Select Session"
                // labelProps={{ className: "font-semibold" }}
                placeholder="Select Session"
                data={[
                  { label: "2023-2024", value: "2024" },
                  { label: "2024-2025", value: "2025" },
                  // { label: "2025-2026", value: "2026" },
                ]}
                value={selectedSession}
                onChange={(e) => setSelectedSession(String(e))}
              />

              {/* <Select
                label="Select month"
                placeholder="Select month"
                data={[
                  { label: "January", value: "01" },
                  { label: "February", value: "02" },
                  { label: "March", value: "03" },
                  { label: "April", value: "04" },
                  { label: "May", value: "05" },
                  { label: "June", value: "06" },
                  { label: "July", value: "07" },
                  { label: "August", value: "08" },
                  { label: "September", value: "09" },
                  { label: "October", value: "10" },
                  { label: "November", value: "11" },
                  { label: "December", value: "12" },
                ]}
                value={selectedMonth}
                onChange={(e) => setSelectedMonth(String(e))}
              /> */}

              <Select
                label="Select month"
                placeholder="Select month"
                data={[
                  {
                    label: "April",
                    value: "04",
                    disabled:
                      selectedSession ==
                        moment().add(1, "year").format("YYYY") &&
                      parseInt(moment().format("MM"), 10) < 4
                        ? true
                        : false,
                  },
                  {
                    label: "May",
                    value: "05",
                    disabled:
                      selectedSession ==
                        moment().add(1, "year").format("YYYY") &&
                      parseInt(moment().format("MM"), 10) < 5
                        ? true
                        : false,
                  },
                  {
                    label: "June",
                    value: "06",
                    disabled:
                      selectedSession ==
                        moment().add(1, "year").format("YYYY") &&
                      parseInt(moment().format("MM"), 10) < 6
                        ? true
                        : false,
                  },
                  {
                    label: "July",
                    value: "07",
                    disabled:
                      selectedSession ==
                        moment().add(1, "year").format("YYYY") &&
                      parseInt(moment().format("MM"), 10) < 7
                        ? true
                        : false,
                  },
                  {
                    label: "August",
                    value: "08",
                    disabled:
                      selectedSession ==
                        moment().add(1, "year").format("YYYY") &&
                      parseInt(moment().format("MM"), 10) < 8
                        ? true
                        : false,
                  },
                  {
                    label: "September",
                    value: "09",
                    disabled:
                      selectedSession ==
                        moment().add(1, "year").format("YYYY") &&
                      parseInt(moment().format("MM"), 10) < 9
                        ? true
                        : false,
                  },
                  {
                    label: "October",
                    value: "10",
                    disabled:
                      selectedSession ==
                        moment().add(1, "year").format("YYYY") &&
                      parseInt(moment().format("MM"), 10) < 10
                        ? true
                        : false,
                  },
                  {
                    label: "November",
                    value: "11",
                    disabled:
                      selectedSession ==
                        moment().add(1, "year").format("YYYY") &&
                      parseInt(moment().format("MM"), 10) < 11
                        ? true
                        : false,
                  },
                  {
                    label: "December",
                    value: "12",
                    disabled:
                      selectedSession ==
                        moment().add(1, "year").format("YYYY") &&
                      parseInt(moment().format("MM"), 10) < 12
                        ? true
                        : false,
                  },
                  {
                    label: "January",
                    value: "01",
                    disabled:
                      selectedSession == moment().add(1, "year").format("YYYY")
                        ? true
                        : false,
                  },
                  {
                    label: "February",
                    value: "02",
                    disabled:
                      selectedSession == moment().add(1, "year").format("YYYY")
                        ? true
                        : false,
                  },
                  {
                    label: "March",
                    value: "03",
                    disabled:
                      selectedSession == moment().add(1, "year").format("YYYY")
                        ? true
                        : false,
                  },
                ]}
                value={selectedMonth}
                onChange={(e) => setSelectedMonth(String(e))}
              />

              <Select
                label="Select District"
                placeholder="Select district"
                clearable
                disabled={
                  role?.includes(ROLES.BLOCK) || role?.includes(ROLES.DISTRICT)
                    ? true
                    : false
                }
                data={districts?.map((each) => {
                  return {
                    label: each.name,
                    value: String(each.id),
                  };
                })}
                value={selectedDistrict ?? null}
                onChange={(e: any) => {
                  setSelectedBlock(undefined);
                  setSelectedCluster(undefined);
                  setSelectedDistrict(e);
                }}
              />
              <Select
                label="Select Block"
                placeholder="Select block"
                clearable
                disabled={
                  !selectedDistrict || role?.includes(ROLES.BLOCK)
                    ? true
                    : false
                }
                data={blocks?.map((each) => {
                  return {
                    label: each.name,
                    value: String(each.id),
                  };
                })}
                value={selectedBlock ?? null}
                onChange={(e: any) => {
                  setSelectedCluster(undefined);
                  setSelectedBlock(e);
                }}
              />
              <Select
                label="Select Cluster"
                placeholder="Select cluster"
                clearable
                disabled={!selectedBlock}
                data={clusterSchools?.map((each) => {
                  return {
                    label: each.cluster_School_Name,
                    value: String(each.cluster_School_Id),
                  };
                })}
                value={selectedCluster ?? null}
                onChange={(e: any) => setSelectedCluster(e)}
              />
            </div>
            <div className="flex gap-3 items-end">
              <EButton
                className="btn-blue"
                disabled={!selectedMonth}
                // disabled={true}
                loading={isSearching}
                onClick={() => {
                  handleSearch();
                }}
              >
                Search
              </EButton>
            </div>
          </div>
        </div>
        {/* Mentoring */}
        <MentoringCompliance />
        {/* Monitoring */}
        <MonitoringCompliance />
        {/* School level Mentoring */}
        <SchoolLevelMentoring />
      </PageLayout>
    </DashboardFilterContext.Provider>
  );
}
