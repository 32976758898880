import { Select, Skeleton } from '@mantine/core';
import { CSVLink } from "react-csv";

import { EButton, EEmpty, EInfoPanel, ETable, EVerticalFormSection } from '../../../../../libs/ui';

import useDistrictWiseCompliance from '../Hooks/useDistrictWiseCompliance';
import moment from 'moment';
import useDashboardVisitComplianceReports from '../Hooks/useDashboardVisitComplianceReports';
import { useContext, useEffect } from 'react';
import { AppContext } from '../../../../../AppContext';
import { ROLES } from '../../../../../Routes/routes';

export default function DistrictWiseCompliance() {

    const {
        user,
        role
    } =useContext(AppContext)

    const {
      isLoading,
      handleSelectMonth,
      districtWiseComplianceReport,
      districtWiseComplianceCSV,
      fetchDistrictWiseCompliance,
      selectedBlock,
      selectedDistrict,
      districts,
      blocks,
      setSelectedBlock,
      setSelectedDistrict,
      selectedMonth,
      setSelectedMonth,
      handelClearFilter,
      selectedSession,
      setSelectedSession,
    } = useDistrictWiseCompliance();

    return (
      <div>
        {/* FILTERS */}
        <div className="filter-container">
          <div className="flex justify-between items-end w-full">
            <div className="flex items-end gap-4">
              <Select
                label="Select Session"
                placeholder="Select Session"
                data={[
                  { label: "2023-2024", value: "2024" },
                  { label: "2024-2025", value: "2025" },
                  // { label: "2025-2026", value: "2026" },
                ]}
                value={selectedSession}
                onChange={(e) => setSelectedSession(String(e))}
              />

              {/* <Select
                label="Select month"
                placeholder="Select month"
                data={[
                  { label: "January", value: "01" },
                  { label: "February", value: "02" },
                  { label: "March", value: "03" },
                  { label: "April", value: "04" },
                  { label: "May", value: "05" },
                  { label: "June", value: "06" },
                  { label: "July", value: "07" },
                  { label: "August", value: "08" },
                  { label: "September", value: "09" },
                  { label: "October", value: "10" },
                  { label: "November", value: "11" },
                  { label: "December", value: "12" },
                ]}
                value={selectedMonth}
                onChange={(e) => setSelectedMonth(String(e))}
              /> */}

              <Select
                label="Select month"
                placeholder="Select month"
                data={[
                  {
                    label: "April",
                    value: "04",
                    disabled:
                      selectedSession ==
                        moment().add(1, "year").format("YYYY") &&
                      parseInt(moment().format("MM"), 10) < 4
                        ? true
                        : false,
                  },
                  {
                    label: "May",
                    value: "05",
                    disabled:
                      selectedSession ==
                        moment().add(1, "year").format("YYYY") &&
                      parseInt(moment().format("MM"), 10) < 5
                        ? true
                        : false,
                  },
                  {
                    label: "June",
                    value: "06",
                    disabled:
                      selectedSession ==
                        moment().add(1, "year").format("YYYY") &&
                      parseInt(moment().format("MM"), 10) < 6
                        ? true
                        : false,
                  },
                  {
                    label: "July",
                    value: "07",
                    disabled:
                      selectedSession ==
                        moment().add(1, "year").format("YYYY") &&
                      parseInt(moment().format("MM"), 10) < 7
                        ? true
                        : false,
                  },
                  {
                    label: "August",
                    value: "08",
                    disabled:
                      selectedSession ==
                        moment().add(1, "year").format("YYYY") &&
                      parseInt(moment().format("MM"), 10) < 8
                        ? true
                        : false,
                  },
                  {
                    label: "September",
                    value: "09",
                    disabled:
                      selectedSession ==
                        moment().add(1, "year").format("YYYY") &&
                      parseInt(moment().format("MM"), 10) < 9
                        ? true
                        : false,
                  },
                  {
                    label: "October",
                    value: "10",
                    disabled:
                      selectedSession ==
                        moment().add(1, "year").format("YYYY") &&
                      parseInt(moment().format("MM"), 10) < 10
                        ? true
                        : false,
                  },
                  {
                    label: "November",
                    value: "11",
                    disabled:
                      selectedSession ==
                        moment().add(1, "year").format("YYYY") &&
                      parseInt(moment().format("MM"), 10) < 11
                        ? true
                        : false,
                  },
                  {
                    label: "December",
                    value: "12",
                    disabled:
                      selectedSession ==
                        moment().add(1, "year").format("YYYY") &&
                      parseInt(moment().format("MM"), 10) < 12
                        ? true
                        : false,
                  },
                  {
                    label: "January",
                    value: "01",
                    disabled:
                      selectedSession == moment().add(1, "year").format("YYYY")
                        ? true
                        : false,
                  },
                  {
                    label: "February",
                    value: "02",
                    disabled:
                      selectedSession == moment().add(1, "year").format("YYYY")
                        ? true
                        : false,
                  },
                  {
                    label: "March",
                    value: "03",
                    disabled:
                      selectedSession == moment().add(1, "year").format("YYYY")
                        ? true
                        : false,
                  },
                ]}
                value={selectedMonth}
                onChange={(e) => setSelectedMonth(String(e))}
              />

              <Select
                label="Select District"
                placeholder="Select district"
                clearable
                disabled={
                  role?.includes(ROLES.BLOCK) || role?.includes(ROLES.DISTRICT)
                    ? true
                    : false
                }
                data={districts?.map((each) => {
                  return {
                    label: each.name,
                    value: String(each.id),
                  };
                })}
                value={selectedDistrict ?? null}
                onChange={(e: any) => {
                  setSelectedBlock(undefined);
                  setSelectedDistrict(e);
                }}
              />
              <Select
                label="Select Block"
                placeholder="Select block"
                clearable
                disabled={
                  !selectedDistrict || role?.includes(ROLES.BLOCK)
                    ? true
                    : false
                }
                data={blocks?.map((each) => {
                  return {
                    label: each.name,
                    value: String(each.id),
                  };
                })}
                value={selectedBlock ?? null}
                onChange={(e: any) => setSelectedBlock(e)}
              />
            </div>
            <div className="flex gap-3">
              <EButton
                className="btn-light"
                disabled={
                  !selectedDistrict ||
                  role?.includes(ROLES.BLOCK) ||
                  role?.includes(ROLES.DISTRICT)
                    ? true
                    : false
                }
                onClick={handelClearFilter}
              >
                Clear
              </EButton>
              <EButton
                className="btn-blue"
                disabled={!selectedMonth}
                loading={isLoading}
                onClick={() => {
                  fetchDistrictWiseCompliance();
                }}
              >
                Search
              </EButton>
            </div>
          </div>
        </div>

        {/* DATA */}
        <div className="mt-5">
          {isLoading ? (
            <Skeleton radius={"md"} height={200} />
          ) : districtWiseComplianceReport.length ? (
            <EVerticalFormSection
              title="Visit Compliance"
              rightPanel={
                <>
                  <CSVLink
                    className="text-blue-600 text-sm hover:underline"
                    data={districtWiseComplianceCSV?.content ?? []}
                    headers={districtWiseComplianceCSV?.headers ?? []}
                    filename={`District_wise_Visit_Compliance_Report_for_${moment(
                      selectedMonth
                    ).format("MMM")}-${moment().format("YYYY")}.csv`}
                  >
                    Download File
                  </CSVLink>
                </>
              }
            >
              <ETable>
                <thead>
                  <tr>
                    <th>Districts / Blocks</th>
                    <th>Number of Mentors</th>
                    <th>Total Classroom Observations</th>
                    <th>Total Unique School Visits</th>
                    {/* <th>Total School Visits</th> */}
                    <th>Total Number of Students Spot Tested</th>
                  </tr>
                </thead>
                <tbody>
                  {districtWiseComplianceReport.map((each, index) => (
                    <tr key={index + "visit_com"}>
                      <td>{each.Name.trim()}</td>
                      <td>{each.Number_Of_Mentors}</td>
                      <td>{each.Total_Classroom_Observations}</td>
                      <td>{each.Total_Unique_School_Visit}</td>
                      {/* <td>{each.Total_School_Visit}</td> */}
                      <td>{each.Total_Student_Spot_Tested}</td>
                    </tr>
                  ))}
                </tbody>
              </ETable>
            </EVerticalFormSection>
          ) : (
            <EInfoPanel title="Please select a month and press search to generate report" />
          )}
        </div>
      </div>
    );
}
